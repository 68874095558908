function ShadowBox() {
  return (
    <div id="preview">
      <div id="corner-tl" className="corner"></div>
      <div id="corner-tr" className="corner"></div>

      <h3>What I Do</h3>
      <p>
        I love the outdoors but I also love building new things and learning new
        technologies :)
      </p>
      <div id="corner-br" className="corner"></div>
      <div id="corner-bl" className="corner"></div>
    </div>
  );
}
export default ShadowBox;
