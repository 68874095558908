import ProjectPost from "../links/ProjectPost";
function Projects() {
  return (
    <div>
      <h3 style={{ textAlign: "center" }}> Some of my past/current projects</h3>

      <ProjectPost />
    </div>
  );
}
export default Projects;
