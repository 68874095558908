import "./css/App.css";
import Header from "./components/sections/Header";
import IntroSection from "./components/sections/IntroSection";
import AboutSection from "./components/sections/AboutSection";
import Projects from "./components/sections/Projects";

function App() {
  return (
    <div>
      <section className="s1">
        <div className="main-container">
          <Header />
          <IntroSection />
        </div>
      </section>
      <section className="s2">
        <div className="main-container">
          <AboutSection />
        </div>
      </section>
      <section className="s1">
        <div className="main-container">
          <Projects />
        </div>
      </section>
    </div>
  );
}

export default App;
