import ContactMe from "../links/ContactMe";
import SocialLinks from "../links/SocialLinks";

function AboutSection() {
  return (
    <div className="about-wrapper">
      <ContactMe />
      <SocialLinks />
    </div>
  );
}
export default AboutSection;
