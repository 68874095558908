import taskReminder from "../../img/taskreminder.png";
import findMyGithub from "../../img/FindMyGithub.png";
import weather from "../../img/weather3.png";
import support from "../../img/Support.png";
import currency from "../../img/currency.png";
import qr from "../../img/qr.jpeg";

function ProjectPost() {
  return (
    <div className="post-wrapper">
      <div>
        <div className="post">
          <img className="thumbnail th2" src={support} alt="Project" />
          <div className="post-preview">
            <h6 className="post-title">Mo-Support Team</h6>
            <p className="post-intro">
              Support Team built using MERN stack. Designed to allow customers
              to submit tickets and allow admin to reply and handle tickets.
            </p>
            <a
              style={{ fontWeight: "bold" }}
              href="https://github.com/Mfarah42/SupportDesk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github Link
            </a>
          </div>
        </div>
      </div>
      <div>
        <div className="post">
          <img className="thumbnail th3" src={findMyGithub} alt="Project" />
          <div className="post-preview">
            <h6 className="post-title">FindMyGithub</h6>
            <p className="post-intro">
              React web app that lets you search for GitHub profiles. App uses
              Context API, useContext and useReducer along with hooks for state
              management.
            </p>
            <a
              style={{ fontWeight: "bold" }}
              href="https://distracted-easley-612209.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Live Demo
            </a>
          </div>
        </div>
      </div>

      <div>
        <div className="post">
          <img className="thumbnail th3" src={taskReminder} alt="Project" />
          <div className="post-preview">
            <h6 className="post-title">Task Reminder</h6>
            <p className="post-intro">
              Task Reminder built using React ui and JSON-server for mock
              backend
            </p>
            <a
              style={{ fontWeight: "bold" }}
              href="https://tender-raman-88acfe.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Live Demo
            </a>
          </div>
        </div>
      </div>
      <div>
        <div className="post">
          <img className="thumbnail th3" src={qr} alt="Project" />
          <div className="post-preview">
            <h6 className="post-title">QR Code Generator</h6>
            <p className="post-intro">
              Quick and easy way to generate and download a QR code to share a
              link with friends. CSS Framework used (Tailwind)
            </p>
            <a
              style={{ fontWeight: "bold" }}
              href="https://willowy-capybara-e14523.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Live Demo
            </a>
          </div>
        </div>
      </div>
      <div>
        <div className="post">
          <img className="thumbnail th3" src={weather} alt="Project" />
          <div className="post-preview">
            <h6 className="post-title">His&Hers WeatherApp</h6>
            <p className="post-intro">
              WeatherApp for long distance couples to compare the weather.
              Fetched weather from API (https://api.openweathermap.org)
            </p>
            <a
              style={{ fontWeight: "bold" }}
              href="https://github.com/Mfarah42/hisandherweatherapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github Link
            </a>
          </div>
        </div>
      </div>
      <div>
        <div className="post">
          <img className="thumbnail th3" src={currency} alt="Project" />
          <div className="post-preview">
            <h6 className="post-title">Currency Exchange Rate</h6>
            <p className="post-intro">
              Simple currency exchange rate, fetches exchange rates from API
              (https://api.exchangerate-api.com)
            </p>
            <a
              style={{ fontWeight: "bold" }}
              href="https://polite-clafoutis-be8977.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Live Demo
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProjectPost;
