import BrowserDots from "../themes/BrowserDots";
import Links from "../links/Links";
import resume from "../../resume/Mohamed Farah Resume.pdf";
function NavSection() {
  return (
    <div className="nav-wrapper">
      <div className="dots-wrappers">
        <BrowserDots id="dot-1" />
        <BrowserDots id="dot-2" />
      </div>
      <div id="navigation">
        <li>
          <Links fa="fas fa-file" link={resume} title=" Resume" />
        </li>
      </div>
    </div>
  );
}
export default NavSection;
