import explore from "../../img/explore.jpeg";
import resume from "../../resume/Mohamed Farah Resume.pdf";
function SocialLinks() {
  return (
    <div className="social-links">
      <img id="social_img" src={explore} />
      <div className="social-background">
        <h4>FIND ME HERE </h4>
        <h6>
          <i className="fab fa-github"></i>{" "}
          <a target="_blank" href="https://github.com/Mfarah42">
            Github
          </a>
        </h6>
        <h6>
          <i className="fab fa-linkedin"></i>{" "}
          <a target="_blank" href="https://www.linkedin.com/in/mfarah42/">
            LinkedIn
          </a>
        </h6>
        <h6>
          <i className="fas fa-file"></i>{" "}
          <a target="_blank" href={resume}>
            Download Resume
          </a>
        </h6>
      </div>
    </div>
  );
}
export default SocialLinks;
