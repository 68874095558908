function Links({ fa, link, title }) {
  return (
    <>
      <i className={fa}></i>
      <a target="_blank" rel="noreferrer" href={link}>
        {title}
      </a>
    </>
  );
}

export default Links;
