function ContactMe() {
  return (
    <div id="contact-me" className="about-me">
      <h4>More about me </h4>
      <p>
        I'm a Software Engineer, passionate about making open-source more
        accessible, love working on technologies that empower people.
      </p>
      <hr></hr>
      <h4>TOP EXPERTISE</h4>
      <p> Fullstack developer with primary focus on MERN stack</p>

      <div id="skills">
        <ul>
          <li>Javascript</li>
          <li>React</li>
          <li>Node.js</li>
          <li>Express.js</li>
          <li>Postgres</li>
          <li>MongoDB</li>
        </ul>
        <ul>
          <li>Python</li>
          <li>Java</li>
          <li>MySQL</li>
          <li>AWS (RDS/S3)</li>
          <li>Heroku</li>
          <li>HTML/CSS</li>
        </ul>
      </div>
    </div>
  );
}

export default ContactMe;
