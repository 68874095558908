import ThemeOptions from "../themes/ThemeOptions";
import profilepic from "../../img/profilePhoto.jpeg";
//import "../css/light.css";

function LeftColumn() {
  let theme = localStorage.getItem("theme");
  setTheme(theme);

  function testFunc(e) {
    e.preventDefault();

    if (e.target.dataset.mode === "light") {
      setLight();
    } else {
      setDark();
    }
  }

  function setTheme(mode) {
    if (mode === "light") {
      setLight();
    } else {
      setDark();
    }
  }
  function setLight() {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  }
  function setDark() {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  }

  return (
    <div className="left-column">
      <img id="profile_pic" src={profilepic} alt="nothing here" />
      <p id="settings-note" style={{ marginTop: "30px" }}>
        *Click for
        <h6
          style={{
            textAlign: "center",
            lineHeight: 1,
            marginBottom: "15px",
            marginTop: "5px",
          }}
          id="theme-choice"
        >
          Dark Mode
        </h6>
      </p>
      <div id="theme-options-wrapper" onClick={testFunc}>
        <ThemeOptions
          mode="dark"
          id="dark-mode"
          theme="theme-dot"
          styleIt={{ display: "none" }}
        />
        <ThemeOptions
          mode="light"
          id="light-mode"
          theme="theme-dot"
          style={{ display: "show" }}
        />
      </div>
      <p id="settings-note">
        *Theme settings will be saved for <br /> your next visit{" "}
      </p>
    </div>
  );
}
export default LeftColumn;
