import ShadowBox from "../themes/ShadowBox";
function RightColumn() {
  return (
    <div className="right-column">
      <div id="preview-shadow">
        <ShadowBox />
      </div>
    </div>
  );
}
export default RightColumn;
