import NavSection from "../introsection/NavSection";
import LeftColumn from "../introsection/LeftColumn";
import RightColumn from "../introsection/RightColumn";
function IntroSection() {
  return (
    <div className="intro-wrapper">
      <NavSection />
      <LeftColumn />
      <RightColumn />
    </div>
  );
}

export default IntroSection;
